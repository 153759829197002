<template>
  <div class="flex flex-col gap-4 items-start">
    <small class="text-orange-700">Deze feature is nog onder ontwikkeling</small>
    <div class="w-full">
      <UIInput
        v-model="form.telefoonnummer"
        label="Telefoonnummer"
        class="w-full max-w-sm"
        placeholder="Telefoonnummer..."
      />
    </div>

    <div class="w-full">
      <UIInput
        v-model="form.bezwaren"
        label="Bezwaren"
        textarea
        class="w-full max-w-3xl"
        rows="4"
        placeholder="Bezwaren..."
      />
    </div>

    <div class="w-full">
      <UIInput
        v-model="form.opmerking"
        textarea
        label="Opmerkingen"
        class="w-full max-w-3xl"
        rows="4"
        placeholder="Opmerkingen..."
      />
    </div>

    <div class="flex flex-row justify-between items-start w-full">
      <div class="flex flex-col">
        <button
          type="submit"
          :disabled="true || isDisabled"
          class="btn bg-teal-500 text-white hover:bg-teal-600 "
          @click.prevent.stop="handleSubmit()"
        >
          Offerte sturen in de plaats
        </button>
        <small class="text-orange-700">Deze feature is nog onder ontwikkeling</small>
      </div>

      <button type="button" class="btn yellow" @click="toonScript = !toonScript">
        Toggle Script
      </button>
    </div>

    <ol v-if="toonScript" class="whitespace-collapse list-decimal list-inside card bg-gray-50 flex flex-col gap-2 mt-8 mb-2 mx-auto">
      <li>Rit <strong>prijs berekenen</strong></li>
      <li>
        <strong>Prijzen voorstellen</strong> (gedeeld / privé)
        <ul class="list-square list-inside ml-4">
          <li>
            <strong class="text-green-500">Positief</strong> antwoord: Meteen <strong>inboeken</strong>
          </li>
          <li>
            <strong class="text-red-500">Negatief</strong> antwoord: <strong>Bezwaren</strong> vragen + Proberen te <strong>overtuigen</strong>
          </li>
        </ul>
      </li>
      <li>
        Klant nog steeds <strong>niet overtuigd</strong>: Bezwaren opschrijven en toch een <strong>offerte sturen</strong>.
      </li>
      <li>
        <strong>Afsluiten</strong> met de klant een fijne dag/avond/weekend/vakantie verder te wensen.
      </li>
    </ol>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'

const props = defineProps({
  telefoonnummer: String,
  disabled: Boolean,
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['update:modelValue', 'submit'])

const GENERATE_DEFAULT_FORM = () => ({
  telefoonnummer: '',
  bezwaren: '',
  opmerking: '',
})

const toonScript = ref(false)

watch(() => props.telefoonnummer, (val, oldVal) => {
  if (val === oldVal) return
  console.log({ val, oldVal })
  if (oldVal !== form.value.telefoonnummer) return
  form.value.telefoonnummer = String(val)
})

const api = useApi()
const form = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
})

form.value = {
  ...GENERATE_DEFAULT_FORM(),
  ...form.value,
}
if (props.telefoonnummer && !form.value.telefoonnummer) {
  form.value.telefoonnummer = props.telefoonnummer
}

const isDisabled = computed(() =>
  props.disabled
  || !!api.loading.value
  || !form.value.telefoonnummer,
)

const postData = computed(() => ({
  ...form.value,
  telefoonnummer: form.value.telefoonnummer?.length ? form.value.telefoonnummer : null,
  bezwaren: form.value.bezwaren?.length ? form.value.bezwaren : null,
  opmerking: form.value.opmerking?.length ? form.value.opmerking : null,
}))

const handleSubmit = () => {
  emit('submit', postData.value)
}
</script>
