<template>
  <div>
    <h1>Offerte #{{ apiDataGet.rit_id }} bewerken</h1>
    <span v-if="loading">Loading ...</span>
    <FormRit
      v-else
      v-model="form"
      is-offerte
      is-bewerken
      @submit="onSubmit"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import FormRit from '@/components/Form/Rit.vue'

// import { dateString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'

const route = useRoute()
const apiDataGet = computed(() => ({ rit_id: route.params.rit_id }))

const api = useApi()
const { loading, data: form } = useGetApi('RIT_BEWERKEN_INFO', apiDataGet, { watch: true })

const onSubmit = data => {
  api('OFFERTE_EDIT', data).then(() => {
    // if (route.query.from) {
    //   useRedirectSafe(decodeURIComponent(route.query.from))
    //   return
    // }
    // const date = dateString(data.heenreis.datum) || undefined
    // useRedirectSafe({ name: 'Kalender', query: { date } })
    useRedirectSafe({ name: 'Boekingen', query: { type: 'OFFERTES' } })
  })
}
</script>
