<template>
  <div>
    <div class="flex flex-col lg:flex-row lg:justify-between mb-2" :class="{ 'bg-orange-50 border-2 border-orange-100 background--dupliceren p-4': dupliceren }">
      <h1 class="bg-white px-2 shadow rounded">
        Rit #{{ apiDataGet.rit_id }} {{ dupliceren ? 'dupliceren' : 'bewerken' }}
      </h1>

      <button v-if="!dupliceren" @click="handleDupliceren">
        <i class="fas fa-sync text-xs"></i> Rit dupliceren
      </button>
    </div>

    <span v-if="loading">Loading ...</span>
    <FormRit
      v-else
      :key="dupliceren"
      v-model="form"
      :dupliceren="dupliceren"
      is-bewerken
      @submit="onSubmit"
      @datum-heenreis-aangepast="lastDateEdited = 'heenreis'"
      @datum-retour-aangepast="lastDateEdited = 'retour'"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import clone from 'just-clone'

import FormRit from '@/components/Form/Rit.vue'

// import { useRoute, useRouter } from 'vue-router'
// import { useStorage } from '@vueuse/core'
import { dateString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'

const api = useApi()
const route = useRoute()
// const router = useRouter()

const apiDataGet = computed(() => ({ rit_id: route.params.rit_id }))
const { loading, data: formFromApi } = useGetApi('RIT_BEWERKEN_INFO', apiDataGet, { watch: true })

// const storageKey = route.query.storageKey || `RitData-${Date.now()}`
// const formInStorage = useStorage(storageKey, {})
// if (!route.query.storageKey) router.replace({ query: { ...route.query, storageKey } })
// const stopSyncToStorage = watch(formFromApi, (val) => formInStorage.value = clone(val))

const form = ref(null)
watch(formFromApi, val => form.value = clone(val))

const dupliceren = ref(false)
const handleDupliceren = () => {
  // stopSyncToStorage()
  const dupliceerForm = clone(form.value)
  delete dupliceerForm.id
  delete dupliceerForm.rit_id
  delete dupliceerForm.planning_optie_heen
  delete dupliceerForm.planning_optie_retour
  if (dupliceerForm?.facturatie) {
    delete dupliceerForm.facturatie.id
  }
  if (dupliceerForm?.klant) {
    delete dupliceerForm.klant.id
    delete dupliceerForm.klant.klant_info_id
  }
  if (dupliceerForm?.reiziger) {
    delete dupliceerForm.reiziger.id
    delete dupliceerForm.reiziger.klant_info_id
  }
  dupliceerForm.bron = 'PHONE'
  form.value = dupliceerForm
  dupliceren.value = true
}

const lastDateEdited = ref('heenreis')

const onSubmit = data => {
  api(dupliceren.value ? 'RIT_CREATE' : 'RIT_EDIT', data).then(() => {
    // if (dupliceren.value && formInStorage) {
    //   formInStorage.value = null
    // }
    let date = dateString(data.heenreis.datum) || undefined
    if (!dupliceren.value && data.is_retour) {
      date = dateString(data[lastDateEdited.value].datum || data.heenreis.datum) || undefined
    }
    useRedirectSafe({ name: 'Kalender', query: { date, rit_id: data.id, retour: lastDateEdited.value !== 'heenreis' } })
  })
}
</script>

<style scoped>
.background--dupliceren {
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23130d53' fill-opacity='0.4'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
</style>
