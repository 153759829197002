<template>
  <div class="flex flex-col gap-2">
    <h1>Nieuwe rit inboeken</h1>

    <FormRit v-model="formInStorage" @submit="onSubmit" />

    <div v-if="formInStorage?.bron === 'PHONE'" class="lg:grid lg:grid-cols-2 lg:gap-4">
      <div class="flex flex-col italic p-2">
        <h2 class="mb-0">
          Telefoongesprek
        </h2>
        <FormTelefoongesprekComponent
          v-model="formTelefoongesprek"
          :disabled="!(formInStorage.prijs > 0)"
          :telefoonnummer="formInStorage.klant.phone"
          class="card shadow w-full"
          @submit="onSubmitOfferte"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStorage } from '@vueuse/core'

import FormRit from '@/components/Form/Rit.vue'
import generateRitFormData from '@/components/Form/Rit/generateRitFormData'
import FormTelefoongesprekComponent from '@/components/Form/Telefoongesprek.vue'

import { dateString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'

const api = useApi()
const route = useRoute()
const router = useRouter()

const formTelefoongesprek = ref({})

const storageKey = route.query.storageKey || `RitData-${Date.now()}`
const formInStorage = useStorage(storageKey, generateRitFormData())
if (!route.query.storageKey) router.replace({ query: { ...route.query, storageKey } })

const onSubmit = data => {
  api('RIT_CREATE', { ...data, telefoongesprek: formTelefoongesprek.value }).then(response => {
    const date = dateString(data.heenreis.datum) || undefined
    if (formInStorage) formInStorage.value = null
    // if (route.query.from) {
    //   useRedirectSafe(decodeURIComponent(route.query.from))
    //   return
    // }
    // console.log({ date, data })
    useRedirectSafe({ name: 'Kalender', query: { date, rit_id: response.data.id, retour: false } })
  })
}

const onSubmitOfferte = data => {
  api('OFFERTE_CREATE', { ...data, telefoongesprek: formTelefoongesprek.value }).then(() => {
    if (formInStorage) formInStorage.value = null
    useRedirectSafe({ name: 'Boekingen', query: { type: 'OFFERTES' } })
  })
}
</script>
